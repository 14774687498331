<template>
  <div id="requestForm">
    <b-card class="mx-2 mx-md-5 mb-3" :header="cardHeader">
      <b-form-group v-if="formFields[view][action].requestId.isVisible" :label="$t('comp.requestform.requestId.label')"
                    :description="$t('comp.requestform.requestId.description')">
        <b-form-input v-model="request.form.requestId" :disabled="formFields[view][action].requestId.isDisabled"/>
      </b-form-group>
      <b-form-group v-if="formFields[view][action].forUser.isVisible" :label="$t('comp.requestform.forUser.label')"
                    :description="$t('comp.requestform.forUser.description')">
        <UserSelect :selected-user.sync="request.form.forUser" :view="view"
                    :is-disabled="formFields[view][action].forUser.isDisabled"/>
      </b-form-group>
      <b-form-group v-if="formFields[view][action].forProject.isVisible" :label="$t('comp.requestform.forProject.label')"
                    :description="$t('comp.requestform.forProject.description')">
        <ProjectSelect :selected-project.sync="request.form.forProject" :view="view" :loadValidity="false"
                       :is-disabled="formFields[view][action].forProject.isDisabled"/>
      </b-form-group>
      <b-form-group v-if="formFields[view][action].message.isVisible" :label="$t('comp.requestform.message.label')"
                    :description="$t('comp.requestform.message.description')">
        <b-form-input type="text" v-model="request.form.message" :disabled="formFields[view][action].message.isDisabled"
                      :required="formFields[view][action].message.isRequired"/>
      </b-form-group>
      <b-form-group v-if="formFields[view][action].state.isVisible" :label="$t('comp.requestform.state.label')" label-for="state"
                    :description="$t('comp.requestform.state.description')">
        <StateSelect :selected-state.sync="request.form.state" entity="request" :view="view"
                     :is-disabled="formFields[view][action].state.isDisabled"/>
      </b-form-group>
    </b-card>
  </div>
</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'
import StateSelect from '@/components/generic/select/StateSelect'
import ProjectSelect from '@/components/generic/select/ProjectSelect'
import UserSelect from '@/components/generic/select/UserSelect'

export default {
  name: 'RequestForm',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    StateSelect,
    ProjectSelect,
    UserSelect
  },
  props: {
    request: {
      type: Object,
      required: true
    },
    action: {
      type: String,
      validator: (action) => {
        return ['add', 'edit'].includes(action)
      }
    },
    view: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      formFields: {
        user: {
          add: {
            requestId: { isVisible: false, isDisabled: true },
            forUser: { isVisible: false, isDisabled: true },
            forProject: { isVisible: true, isDisabled: false },
            message: { isVisible: true, isDisabled: false, isRequired: true },
            state: { isVisible: false, isDisabled: true }
          },
          withdraw: {
            requestId: { isVisible: false, isDisabled: true },
            forUser: { isVisible: false, isDisabled: true },
            forProject: { isVisible: true, isDisabled: true },
            message: { isVisible: true, isDisabled: false, isRequired: false },
            state: { isVisible: false, isDisabled: true }
          },
          answerFollowUp: {
            requestId: { isVisible: false, isDisabled: true },
            forUser: { isVisible: false, isDisabled: true },
            forProject: { isVisible: true, isDisabled: true },
            message: { isVisible: true, isDisabled: false, isRequired: true },
            state: { isVisible: false, isDisabled: true }
          }
        },
        manager: {
          approve: {
            requestId: { isVisible: true, isDisabled: true },
            forUser: { isVisible: true, isDisabled: true },
            forProject: { isVisible: true, isDisabled: true },
            message: { isVisible: true, isDisabled: false, isRequired: false },
            state: { isVisible: true, isDisabled: true }
          },
          decline: {
            requestId: { isVisible: true, isDisabled: true },
            forUser: { isVisible: true, isDisabled: true },
            forProject: { isVisible: true, isDisabled: true },
            message: { isVisible: true, isDisabled: false, isRequired: true },
            state: { isVisible: true, isDisabled: true }
          },
          addFollowUp: {
            requestId: { isVisible: true, isDisabled: true },
            forUser: { isVisible: true, isDisabled: true },
            forProject: { isVisible: true, isDisabled: true },
            message: { isVisible: true, isDisabled: false, isRequired: true },
            state: { isVisible: true, isDisabled: true }
          }
        },
        admin: {
          add: {
            requestId: { isVisible: true, isDisabled: false },
            forUser: { isVisible: true, isDisabled: false },
            forProject: { isVisible: true, isDisabled: false },
            message: { isVisible: true, isDisabled: false },
            state: { isVisible: true, isDisabled: false }
          },
          edit: {
            requestId: { isVisible: true, isDisabled: true },
            forUser: { isVisible: true, isDisabled: false },
            forProject: { isVisible: true, isDisabled: false },
            message: { isVisible: true, isDisabled: false },
            state: { isVisible: true, isDisabled: false }
          }
        }
      }
    }
  },
  computed: {
    cardHeader () {
      if (this.action === 'approve') {
        return this.$i18n.t('comp.requestform.approve.label')
      } else if (this.action === 'decline') {
        return this.$i18n.t('comp.requestform.decline.label')
      } else if (this.action === 'add') {
        return this.$i18n.t('comp.requestform.add.label')
      } else if (this.action === 'withdraw') {
        return this.$i18n.t('comp.requestform.withdraw.label')
      } else if (this.action === 'addFollowUp') {
        return this.$i18n.t('comp.requestform.addFollowUp.label')
      } else if (this.action === 'answerFollowUp') {
        return this.$i18n.t('comp.requestform.answerFollowUp.label')
      } else {
        return ''
      }
    }
  },
  methods: {
  }
}
</script>

<style scoped>
</style>
