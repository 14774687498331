<template>
  <div id="usageBars">
    <div v-if="hasData" >
      <!-- resourceType storage disabled until data structur and interval defined -->
      <b-form-group v-if="false" :label="$t('comp.usagebars.restype.label')">
        <b-form-select v-model="selectedResourceType" :options="resourceTypeOptions" @change="repopulateBars()" id="resourcetype-select"></b-form-select>
      </b-form-group>
      <b-form-group v-if="view === 'manager' || view === 'user'" :description="$t('comp.usagebars.year.label')">
        <b-form-select v-model="selectedYear" :options="yearOptions"></b-form-select>
      </b-form-group>
      <Bar
        :chart-options="chartOptions"
        :chart-data="chartData"
        chart-id="bar-chart"
        dataset-id-key="label"
        :plugins="plugins"
        css-classes=""
        :styles="styles"
        :width="400"
        :height="height"
      />
    </div>
    <div v-else>
        {{ $t('comp.usagebars.nodata', {type: this.type, target: this.target}) }}
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { i18nMixin } from '@/mixins/i18n.mixin'
import { Bar } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import UsageService from '@/services/usage.service'
import yearSelectMixin from '@/mixins/yearSelect.mixin'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'UsageBars',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin, yearSelectMixin],
  components: { Bar },
  props: {
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Object,
      default: () => {}
    },
    target: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    view: {
      type: String,
      required: true
    },
    forProject: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
      default: 250
    },
    queryYear: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      hasData: false,
      chartData: {
        labels: [],
        datasets: []
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      },
      dataset: null,
      emptyDataset: {
        label: '',
        backgroundColor: '',
        data: []
      },
      selectedResourceType: 'compute',
      resourceTypeOptions: [
        { value: 'compute', text: this.$i18n.t('comp.usagebars.restype.compute') },
        { value: 'storage', text: this.$i18n.t('comp.usagebars.restype.storage') }
      ],
      // http://tsitsul.in/blog/coloropt/ - Normal 6
      colors: ['#4053d3', '#ddb310', '#b51d14', '#00beff', '#fb49b0', '#00b25d', '#cacaca']
    }
  },
  created () {
    this.hasData = false
    if (this.queryYear !== 0) { // External input year to load first
      if (this.queryYear === this.selectedYear) { // If equal to default year: Force populateBars with external input
        this.populateBars(this.view, this.type, this.target, this.selectedResourceType, this.queryYear, this.forProject)
      } else { // If different: Trigger watcher for selectedYear
        this.selectedYear = this.queryYear
      }
    } else { // Default year init if no external input
      this.populateBars(this.view, this.type, this.target, this.selectedResourceType, this.selectedYear, this.forProject)
    }
  },
  beforeUpdate () {
    this.chartData.labels = [
      this.$i18n.t('comp.usagebars.month.jan'),
      this.$i18n.t('comp.usagebars.month.feb'),
      this.$i18n.t('comp.usagebars.month.mar'),
      this.$i18n.t('comp.usagebars.month.apr'),
      this.$i18n.t('comp.usagebars.month.may'),
      this.$i18n.t('comp.usagebars.month.jun'),
      this.$i18n.t('comp.usagebars.month.jul'),
      this.$i18n.t('comp.usagebars.month.aug'),
      this.$i18n.t('comp.usagebars.month.sep'),
      this.$i18n.t('comp.usagebars.month.oct'),
      this.$i18n.t('comp.usagebars.month.nov'),
      this.$i18n.t('comp.usagebars.month.dec')
    ]
  },
  watch: {
    queryYear (newYear, oldYear) { // Required for admin views: Year is changed externally: Trigger selectedYear watcher
      this.selectedYear = newYear
    },
    selectedYear (newYear, oldYear) {
      this.repopulateBars(newYear)
    }
  },
  methods: {
    populateBars (view, type, target, resourceType, year, forProject) {
      const usageService = new UsageService(view)
      usageService.getDataForBarplot(target, { targetType: type, resourceType: resourceType, year: year, forProject: forProject }).then(
        response => {
          // console.log('Found ' + view + ' -> ' + type + ' barplot data for ' + resourceType)
          this.hasData = true
          if (!_.isEmpty(response)) {
            response.forEach((elem, index) => {
              this.dataset = _.cloneDeep(this.emptyDataset)
              this.dataset.label = elem.label
              this.dataset.backgroundColor = this.colors[index]
              this.dataset.data = elem.data
              this.chartData.datasets.push(this.dataset)
            })
          } else {
            this.dataset = _.cloneDeep(this.emptyDataset)
            this.dataset.label = 'No Data'
            this.dataset.backgroundColor = this.colors[0]
            this.dataset.data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            this.chartData.datasets.push(this.dataset)
          }
          this.dataset = null
        }
      ).catch(
        error => {
          console.log('Error while requesting ' + type + 'barplot data as ' + view + ': ' + error)
          // If No Data: Do not Display - BUT if data in year X but not in Y: Visibility already active -> Render No Data Dataset
          if (this.hasData) {
            this.dataset = _.cloneDeep(this.emptyDataset)
            this.dataset.label = 'No Data'
            this.dataset.backgroundColor = this.colors[0]
            this.dataset.data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            this.chartData.datasets.push(this.dataset)
            this.dataset = null
          }
        }
      )
    },
    repopulateBars (newYear) {
      this.chartData.datasets = []
      this.populateBars(this.view, this.type, this.target, this.selectedResourceType, newYear, this.forProject)
    }
  }
}
</script>
