<template>
  <div id="accountUsageInfo">
    <div v-if="hasData" >
      <!-- USER -->
      <div v-if="view === 'user'">
        <b-card class="mb-3">
          <template #header>
            <b-icon-bar-chart-line-fill class="mr-1"/> {{ $t('comp.accountusageinfo.usage.title') }}
          </template>
          <b-card-text class="mx-0 mx-md-3">
            <b-form-group :description="$t('comp.accountusageinfo.selectyear.description')">
              <b-form-select v-model="selectedYear" :options="yearOptions"></b-form-select>
            </b-form-group>
            <hr/>
            <div v-for="usage in usages" :key="usage.usageId">
              <ul v-if="usage.forYear == selectedYear">
                <li><strong>{{ $t('comp.accountusageinfo.resource.label') }}:</strong> {{ usage.resource }}</li>
                <li v-if="selectedYear == thisYear"><strong>{{ $t('comp.accountusageinfo.thismonth.label') }}:</strong> {{ usage.thisMonth }} {{ usage.unit }}</li>
                <li v-if="usage.yearTotal"><strong>{{ $t('comp.accountusageinfo.yeartotal.label') }}:</strong> {{ usage.yearTotal }} {{ usage.unit }}</li>
                <li><strong>{{ $t('comp.accountusageinfo.nummonths.label') }}:</strong> {{ usage.numMonths }}</li>
              </ul>
              <hr v-if="usage.forYear == selectedYear"/>
            </div>
            <div v-if="!usages.some(u => u.forYear === selectedYear)">
              <p>{{ $t('comp.accountusageinfo.resource.nodata', {year: selectedYear}) }}</p>
              <hr/>
            </div>
            <b-button variant="primary" class="w-100" v-b-modal="`usage-modal-${account}`">
              <b-icon-box-arrow-up-right class="mr-2"/> {{ $t('comp.accountusageinfo.showusage.label') }}
            </b-button>
          </b-card-text>
        </b-card>
        <b-modal :id="`usage-modal-${account}`" :title="$t('comp.accountusageinfo.usage.display.label', { account: account })" size="xl">
          <div class="overflow-auto px-md-5">
            <UsageBars :view="this.view" :type="this.type" :target="account" :queryYear="selectedYear" :height="400"/>
          </div>
        </b-modal>
      </div>
      <!-- MANAGER -->
      <div v-else-if="view === 'manager'">
        <hr/>
        <div class="accordion" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle="`accountusageinfo-${account}`" variant="light" class="text-left">
                <b-icon-bar-chart-line-fill/> {{ $t('comp.accountusageinfo.usage.title') }}
                <span class="when-open"><b-icon-arrow-up font-scale="0.75"/></span>
                <span class="when-closed"><b-icon-arrow-down font-scale="0.75"/></span>
              </b-button>
            </b-card-header>
            <b-collapse :id="`accountusageinfo-${account}`" accordion="accountusageinfos" role="tabpanel">
              <b-card-body>
                <b-card-text class="mx-0 mx-md-3">
                  <b-form-group :description="$t('comp.accountusageinfo.selectyear.description')">
                    <b-form-select v-model="selectedYear" :options="yearOptions"></b-form-select>
                  </b-form-group>
                  <hr/>
                  <div v-for="usage in usages" :key="usage.usageId">
                    <ul v-if="usage.forYear == selectedYear">
                      <li><strong>{{ $t('comp.accountusageinfo.resource.label') }}:</strong> {{ usage.resource }}</li>
                      <li v-if="selectedYear == thisYear"><strong>{{ $t('comp.accountusageinfo.thismonth.label') }}:</strong> {{ usage.thisMonth }} {{ usage.unit }}</li>
                      <li><strong>{{ $t('comp.accountusageinfo.nummonths.label') }}:</strong> {{ usage.numMonths }}</li>
                      <li v-if="usage.yearTotal"><strong>{{ $t('comp.accountusageinfo.yeartotal.label') }}:</strong> {{ usage.yearTotal }} {{ usage.unit }}</li>
                    </ul>
                    <hr v-if="usage.forYear == selectedYear"/>
                  </div>
                  <div v-if="!usages.some(u => u.forYear === selectedYear)">
                    <p>{{ $t('comp.accountusageinfo.resource.nodata', {year: selectedYear}) }}</p>
                    <hr/>
                  </div>
                  <b-button variant="primary" class="w-100" v-b-modal="`usage-modal-${account}`">
                    <b-icon-box-arrow-up-right class="mr-2"/> {{ $t('comp.accountusageinfo.showusage.label') }}
                  </b-button>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <b-modal :id="`usage-modal-${account}`" :title="$t('comp.accountusageinfo.usage.display.label', { account: account })" size="xl">
          <div class="overflow-auto px-md-5">
            <UsageBars :view="this.view" :type="this.type" :target="this.account" :for-project="this.forProject" :queryYear="selectedYear"  :height="400"/>
          </div>
        </b-modal>
      </div>
      <!-- ADMIN / SUPPORT / ADVISOR -->
      <div v-else>
        <b-form-group :description="$t('comp.accountusageinfo.selectyear.description')">
          <b-form-select v-model="selectedYear" :options="yearOptions"></b-form-select>
        </b-form-group>
        <div class="accordion" role="tablist">
          <b-card v-for="(project, index) in usages" :key="project.key" no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle="`accountusage-${project.key}`" variant="light" class="text-left">
                <b-icon-journal-check class="mr-2"/>
                {{ project.label }}
                <span class="when-open"><b-icon-arrow-up font-scale="0.75"/></span>
                <span class="when-closed"><b-icon-arrow-down font-scale="0.75"/></span>
              </b-button>
            </b-card-header>
            <b-collapse v-if="index == 0" :id="`accountusage-${project.key}`" accordion="accountusages" role="tabpanel" visible>
              <b-card-body class="pb-1" v-if="project.usages.some(u => u.forYear === selectedYear)">
                <b-card-text class="d-md-flex flex-wrap">
                  <div v-for="usage in project.usages" :key="usage.usageId" :class="(usage.forYear == selectedYear) ? 'w-50' : 'w-auto'">
                    <b-card v-if="usage.forYear == selectedYear" class="mb-3 mr-3">
                      <template #header>
                        <b-icon-hdd-rack class="mr-2"/> {{ usage.resource }}
                      </template>
                      <b-card-text>
                        <li v-if="selectedYear == thisYear"><strong>{{ $t('comp.accountusageinfo.thismonth.label') }}:</strong> {{ usage.thisMonth }} {{ usage.unit }}</li>
                        <li v-if="usage.yearTotal"><strong>{{ $t('comp.accountusageinfo.yeartotal.label') }}:</strong> {{ usage.yearTotal }} {{ usage.unit }}</li>
                        <li><strong>{{ $t('comp.accountusageinfo.nummonths.label') }}:</strong> {{ usage.numMonths }}</li>
                      </b-card-text>
                    </b-card>
                  </div>
                </b-card-text>
              </b-card-body>
              <b-card-body v-else>
                <b-card-text>
                  {{ $t('comp.accountusageinfo.resource.nodatacard', {year: selectedYear}) }}
                </b-card-text>
              </b-card-body>
            </b-collapse>
            <b-collapse v-else :id="`accountusage-${project.key}`" accordion="accountusages" role="tabpanel">
              <b-card-body class="pb-1" v-if="project.usages.some(u => u.forYear === selectedYear)">
                <b-card-text class="d-md-flex flex-wrap">
                  <div v-for="usage in project.usages" :key="usage.usageId" :class="(usage.forYear == selectedYear) ? 'w-50' : 'w-auto'">
                    <b-card v-if="usage.forYear == selectedYear" class="mb-3 mr-3">
                      <template #header>
                        <b-icon-hdd-rack class="mr-2"/> {{ usage.resource }}
                      </template>
                      <b-card-text>
                        <li v-if="selectedYear == thisYear"><strong>{{ $t('comp.accountusageinfo.thismonth.label') }}:</strong> {{ usage.thisMonth }} {{ usage.unit }}</li>
                        <li v-if="usage.yearTotal"><strong>{{ $t('comp.accountusageinfo.yeartotal.label') }}:</strong> {{ usage.yearTotal }} {{ usage.unit }}</li>
                        <li><strong>{{ $t('comp.accountusageinfo.nummonths.label') }}:</strong> {{ usage.numMonths }}</li>
                      </b-card-text>
                    </b-card>
                  </div>
                </b-card-text>
              </b-card-body>
              <b-card-body v-else>
                <b-card-text>
                  {{ $t('comp.accountusageinfo.resource.nodatacard', {year: selectedYear}) }}
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <hr class="my-3"/>
        <UsageBars :view="this.view" :type="this.type" :target="this.account" :queryYear="selectedYear"/>
      </div>
    </div>
    <div v-else>
      <hr v-if="view === 'user' || view === 'manager'"/>
      <div class="ml-4">
        {{ $t('comp.accountusageinfo.nodata', {type: this.type, target: account}) }}
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import UsageService from '@/services/usage.service'
import UsageBars from '@/components/generic/chart/UsageBars'
import { i18nMixin } from '@/mixins/i18n.mixin'
import yearSelectMixin from '@/mixins/yearSelect.mixin'

export default {
  name: 'AccountUsageInfo',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin, yearSelectMixin],
  components: {
    UsageBars
  },
  props: {
    account: {
      type: String,
      required: true
    },
    forProject: {
      type: String,
      default: ''
    },
    view: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      type: 'account',
      hasData: false,
      usages: []
    }
  },
  created () {
    this.hasData = false
    this.getUsages(this.account)
  },
  computed: {
  },
  methods: {
    getUsages (username) {
      if (this.view === 'user') {
        return this.$store.dispatch({ type: 'user/getUsages', targetId: username }).then(
          response => {
            if (!_.isEmpty(response)) {
              this.hasData = true
              this.usages = response
            }
          }
        ).catch(
          error => {
            console.log('Error while requesting account usage as user: ' + error)
          }
        )
      } else if (this.view === 'manager') {
        return this.$store.dispatch({ type: 'manager/getAccountUsages', targetId: username, forProject: this.forProject }).then(
          response => {
            if (!_.isEmpty(response)) {
              this.hasData = true
              this.usages = response
            }
          }
        ).catch(
          error => {
            console.log('Error while requesting account usage as manager: ' + error)
          }
        )
      } else if (this.view === 'admin' || this.view === 'support' || this.view === 'advisor') {
        const usageService = new UsageService(this.view)
        usageService.getCurrentForAccount(username).then(
          response => {
            if (!_.isEmpty(response)) {
              this.hasData = true
              this.usages = response
            }
          }
        ).catch(
          error => {
            console.log('Error while requesting account usage as admin or support or advisor: ' + error)
          }
        )
      }
    }
  }
}
</script>

<style scoped>
.collapsed > .when-closed,
.not-collapsed > .when-open {
  float: right;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
