<template>
  <div class="stateSelect" v-show="messagesAvailable">
    <multiselect v-model="selected" :options="options" @input="onInput" track-by="key" label="label"
                 :multiple="isMultiple" :close-on-select="isCloseOnSelect" :internal-search="true"
                 :clear-on-select="isClearOnSelect" :preserve-search="false" :preselect-first="false"
                 :placeholder="placeholder" :selectLabel="$t('select.selectLabel')"
                 :selectedLabel="$t('select.selectedLabel')" :deselectLabel="$t('select.deselectLabel')"
                 :blockKeys="['Delete']" :disabled="isDisabled">
    </multiselect>
  </div>

</template>

<script>
import _ from 'lodash'
import { i18nMixin } from '@/mixins/i18n.mixin'
import Multiselect from 'vue-multiselect'
import StateService from '@/services/state.service'

export default {
  name: 'StateSelect',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    Multiselect
  },
  props: {
    selectedState: {
      type: String,
      default: null
    },
    selectedStates: {
      type: Array,
      default: null
    },
    view: {
      type: String,
      required: true
    },
    entity: {
      type: String,
      required: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isMultiple: false,
      options: [],
      selected: null
    }
  },
  beforeMount () {
    this.setIsMultiple()
    if (this.view !== 'support') this.loadStateOptions()
    this.isMultiple ? this.preProcessSelectedStates() : this.preProcessSelectedState()
  },
  computed: {
    isCloseOnSelect () {
      return !this.isMultiple
    },
    isClearOnSelect () {
      return this.isMultiple
    },
    placeholder () {
      if (this.isMultiple) {
        return this.$i18n.t('comp.stateselect.states.placeholder')
      } else {
        return this.$i18n.t('comp.stateselect.state.placeholder')
      }
    }
  },
  watch: {
    selectedState: function () {
      this.preProcessSelectedState()
    },
    selectedStates: function () {
      this.preProcessSelectedStates()
    }
  },
  methods: {
    setIsMultiple () {
      if (this.selectedStates) { this.isMultiple = true }
    },
    loadStateOptions () {
      const stateService = new StateService(this.view, this.entity)
      stateService.select().then((states) => {
        this.options = states
      }).then(() => {
        this.preProcessOptions()
      }).catch(error => {
        console.error(error)
      })
    },
    generateLabel (key) {
      return this.messagesAvailable ? this.$i18n.t(`states.${key}.label`) : key
    },
    preProcessOptions () {
      _.each(this.options, (option) => {
        option.label = this.generateLabel(option.key)
      })
      this.options.sort((a, b) => String(a.label).localeCompare(String(b.label)))
    },
    preProcessSelectedState () {
      this.selected = this.selectedState
        ? { key: this.selectedState, label: this.generateLabel(this.selectedState) }
        : null
    },
    postProcessSelectedState (selectedState) {
      return selectedState && selectedState.key ? selectedState.key : ''
    },
    preProcessSelectedStates () {
      const states = []
      if (this.selectedStates) {
        _.each(this.selectedStates, (state) => {
          states.push({ key: state, label: this.generateLabel(state) })
        })
      }
      this.selected = states
    },
    postProcessSelectedStates (selectedStates) {
      return selectedStates ? _.map(selectedStates, 'key') : []
    },
    onInput (value) {
      if (this.isMultiple) {
        this.$emit('update:selectedStates', this.postProcessSelectedStates(value))
      } else {
        this.$emit('update:selectedState', this.postProcessSelectedState(value))
      }
    }
  }
}
</script>
