<template>
  <div class="projectSelect" v-show="messagesAvailable">
    <multiselect v-model="selected" :options="options" :options-limit="50" @input="onInput" @close="onClose"
                 @search-change="findProjects" track-by="key" label="label" :multiple="isMultiple"
                 :close-on-select="isCloseOnSelect" :internal-search="false" :loading="isLoading"
                 :clear-on-select="isClearOnSelect" :preserve-search="false" :preselect-first="false"
                 :placeholder="$t('comp.projectselect.project.placeholder')" :selectLabel="$t('select.selectLabel')"
                 :selectedLabel="$t('select.selectedLabel')" :deselectLabel="$t('select.deselectLabel')"
                 :blockKeys="['Delete']" :disabled="isDisabled" :custom-label="selectLabel">
      <template slot="noOptions">{{ $t('select.noOptions') }}</template>
      <template slot="noResult">{{ $t('select.noResults') }}</template>
    </multiselect>
  </div>

</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'
import Multiselect from 'vue-multiselect'
import ProjectService from '@/services/project.service'

export default {
  name: 'ProjectSelect',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    Multiselect
  },
  props: {
    selectedProject: {
      type: Object,
      default: null
    },
    selectedValidity: {
      type: Date,
      default: null
    },
    selectedProjects: {
      type: Array,
      default: null
    },
    view: {
      type: String,
      required: true
    },
    // prefix: {
    //   type: String,
    //   default: ''
    // },
    isDisabled: {
      type: Boolean,
      default: false
    },
    loadValidity: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      query: '',
      options: [],
      dateOptions: [],
      selected: false,
      isLoading: false,
      isMultiple: false
    }
  },
  computed: {
    isCloseOnSelect () {
      return !this.isMultiple
    },
    isClearOnSelect () {
      return this.isMultiple
    }
  },
  watch: {
    selectedProject: function () {
      this.preProcessSelectedProject()
    },
    selectedProjects: function () {
      this.preProcessSelectedProjects()
    }
  },
  beforeMount () {
    this.setIsMultiple()
    this.isMultiple ? this.preProcessSelectedProjects() : this.preProcessSelectedProject()
  },
  methods: {
    setIsMultiple () {
      if (this.selectedProjects) { this.isMultiple = true }
    },
    findProjects (query) {
      if (query && query.length >= 3) { // && this.view !== 'support'
        this.isLoading = true
        const projectService = new ProjectService(this.view)
        projectService.select({ filter: query, loadValidity: this.loadValidity }).then((response) => { // Add 'prefix: this.prefix' to reactivate prefix prefilter
          if (this.loadValidity) {
            this.options = response[0] // Sorted in Backend
            this.dateOptions = response[1]
            this.isLoading = false
          } else {
            this.options = response[0]
            this.isLoading = false
          }
        }).catch(error => {
          this.options = []
          this.dateOptions = []
          this.isLoading = false
          console.error(error)
        })
      }
    },
    reset () {
      this.query = ''
      this.options = []
      this.dateOptions = []
    },
    onClose () {
      this.reset()
    },
    onInput (value) {
      if (this.isMultiple) {
        this.$emit('update:selectedProjects', value)
      } else if (this.loadValidity) {
        this.$emit('update:selectedProject', value)
        this.$emit('update:selectedValidity', new Date(Date.parse(this.dateOptions[this.options.indexOf(value)])))
      } else {
        this.$emit('update:selectedProject', value)
      }
      this.reset()
    },
    preProcessSelectedProject () {
      this.selected = this.selectedProject ? this.selectedProject : null
    },
    preProcessSelectedProjects () {
      this.selected = this.selectedProjects ? this.selectedProjects : []
    },
    selectLabel ({ key, label }) {
      // NOT persisted in DB in this form
      return `${key}: ${label}`
    }
  }
}
</script>
