import DefaultApiService from '@/services/defaultApi.service'

const ENDPOINT = 'states'

const VIEW_ADMIN = 'admin'
const VIEW_MANAGER = 'manager'
const VIEW_USER = 'user'

class StateService {
  constructor (view, entity) {
    this.view = view
    this.entity = entity
  }

  count (params = {}) {
    params.view = this.view
    params.type = 'metadata'
    return DefaultApiService.count(ENDPOINT, params)
  }

  list (params = {}) {
    params.view = this.view
    params.type = 'table'
    return DefaultApiService.list(ENDPOINT, params)
  }

  select (params = {}) {
    params.view = this.view
    params.entity = this.entity
    params.type = 'select'
    return DefaultApiService.list(ENDPOINT, params)
  }

  get (id, params = {}) {
    params.view = this.view
    return DefaultApiService.get(ENDPOINT, id, params)
  }

  create (data, params = {}) {
    params.view = this.view
    return DefaultApiService.create(ENDPOINT, data, params)
  }

  update (data, params = {}) {
    params.view = this.view
    return DefaultApiService.update(ENDPOINT, data, params)
  }

  delete (id, params = {}) {
    params.view = this.view
    return DefaultApiService.delete(ENDPOINT, id, params)
  }
}

export default StateService
export const stateServiceForUserView = new StateService(VIEW_USER)
export const stateServiceForManagerView = new StateService(VIEW_MANAGER)
export const stateServiceForAdminView = new StateService(VIEW_ADMIN)
