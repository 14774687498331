<template>
  <div id="accountInfo">
    <ul>
      <li v-if="(view === 'admin' || view === 'support' || view === 'advisor')"><strong>{{ $t('comp.accountinfo.uid.label') }}:</strong> {{ account.uid }}</li>
      <li><strong>{{ $t('comp.accountinfo.username.label') }}:</strong> {{ account.username }}</li>
      <li><strong>{{ $t('comp.accountinfo.homeDirectory.label') }}:</strong> {{ account.homeDirectory }}</li>
      <li><strong>{{ $t('comp.accountinfo.user.label') }}:</strong> {{ accountUser }}</li>
      <li v-if="accountMail"><strong>{{ $t('comp.accountinfo.mail.label') }}:</strong> {{ accountMail }}</li>
      <li><strong>{{ $t('comp.accountinfo.shell.label') }}:</strong> {{ account.shell }}</li>
      <li><strong>{{ $t('comp.accountinfo.state.label') }}:</strong> {{ $t(`states.${account.state}.label`) }}</li>
      <li>
        <strong>{{ $t('comp.accountinfo.validuntil.label') }}:</strong>
        {{ formatDate(account.validUntil) }}
        <b-icon-info-circle-fill v-if="account.willExpire && account.state === 'active'" variant="warning" class="ml-1"/>
        <b-icon-exclamation-circle-fill v-if="account.isExpired  && account.state === 'active'" variant="danger"  class="ml-1"/>
      </li>
      <ul v-if="account.willExpire">
        <li><strong>{{ $t('comp.accountinfo.note') }}:</strong> {{ $t('comp.accountinfo.willExpire.text') }}</li>
        <li><strong>{{ $t('comp.accountinfo.lastNotification.label') }}:</strong> {{ formatDate(account.lastNotification) }}</li>
      </ul>
      <ul v-if="account.isExpired && account.state === 'active'">
        <li><strong>{{ $t('comp.accountinfo.caution') }}:</strong> {{ $t('comp.accountinfo.gracePeriod.text') }}</li>
        <li><strong>{{ $t('comp.accountinfo.gracePeriod.label') }}:</strong> {{ formatDate(account.graceLimit) }}</li>
        <li><strong>{{ $t('comp.accountinfo.lastNotification.label') }}:</strong> {{ formatDate(account.lastNotification) }}</li>
      </ul>
      <li v-if="view !== 'user'">
        <strong>{{ $t('comp.accountinfo.publickeysfound.label') }}:</strong>
        {{ $t(`publickeysfound.${account.publicKeysFound}.label`) }}
        <b-iconstack v-if="!account.publicKeysFound && account.state === 'active'" class="ml-1">
          <b-icon stacked icon="circle-fill" variant="danger"/>
          <b-icon stacked icon="key-fill" scale="0.75" variant="light"/>
        </b-iconstack>
      </li>
      <li v-if="(view === 'admin' || view === 'support' || view === 'advisor')"><strong>{{ $t('comp.accountinfo.createdat.label') }}:</strong> {{ formatDate(account.createdAt) }}</li>
      <li v-if="(view === 'admin' || view === 'support' || view === 'advisor') && hasPrefix"><strong>{{ $t('comp.accountinfo.prefix.label') }}:</strong> {{ account.prefix }}</li>
    </ul>
    <ul v-if="view === 'user'" class="mt-2">
      <!-- PI -->
      <li><strong>{{ $t('comp.accountinfo.pi.label') }}:</strong> {{ account?.pi ? account.pi : $t('comp.accountinfo.nopi') }}</li>
      <!-- ADVISORS -->
      <li><strong>{{ $t('comp.accountinfo.advisors.label') }}:</strong>
        <span v-if="!account?.advisors || account.advisors.length === 0"> {{ $t('comp.accountinfo.noadvisors') }} </span>
        <span v-else-if="account.advisors.length === 1"> {{ account.advisors[0].label }} </span>
        <span v-else>
          <ul>
            <li v-for="advisor in account.advisors" :key="advisor.key">
              {{ advisor.label }}
            </li>
          </ul>
        </span>
      </li>
      <!-- MANAGERS -->
      <li><strong>{{ $t('comp.accountinfo.managers.label') }}:</strong>
        <span v-if="!account?.managers || account.managers.length === 0"> {{ $t('comp.accountinfo.nomanagers') }} </span>
        <span v-else-if="account.managers.length === 1"> {{ account.managers[0].label }} </span>
        <span v-else>
          <ul>
            <li v-for="manager in account.managers" :key="manager.key">
              {{ manager.label }}
            </li>
          </ul>
        </span>
      </li>
       <!-- OTHER MANAGERS -->
      <hr v-if="account.otherManagers"/>
      <li v-if="account.otherManagers">
        <strong>{{ $t('comp.accountinfo.othermanagers.label') }}:</strong>
        <ul>
          <li v-for="otherManagers in account.otherManagers" :key="otherManagers.key">
            {{ $t('comp.accountinfo.project.label') }} "{{ otherManagers.key }}":
              <ul>
                <li v-for="(managerLabel, idx) in otherManagers.label" :key="idx">
                  {{ managerLabel }}
                </li>
              </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import moment from 'moment'
import { i18nMixin } from '@/mixins/i18n.mixin'

export default {
  name: 'AccountInfo',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
  },
  props: {
    account: {
      type: Object,
      required: true
    },
    view: {
      type: String,
      required: true
    }
  },
  data () {
    return {}
  },
  computed: {
    hasPrefix: function () {
      return (this?.account?.prefix)
    },
    accountUser: function () {
      if (this?.account?.user?.label) {
        const result = /^(.*), (.*) \(.*\)$/.exec(this?.account?.user?.label)
        return `${result[2]} ${result[1]}`
      } else {
        return 'No User'
      }
    },
    accountMail: function () {
      if (this?.account?.user?.label) {
        const result = /^.*, .* \((ID: )?(.*)\)$/.exec(this?.account?.user?.label)
        return result[1] ? false : result[2]
      } else {
        return 'No Mail'
      }
    }
  },
  methods: {
    formatDate (date) {
      return date ? moment(date).format('YYYY-MM-DD HH:mm') : '-'
    }
  }
}
</script>

<style scoped>
</style>
