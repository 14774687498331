<template>
  <div class="userSelect" v-show="messagesAvailable">
    <multiselect v-model="selected" :options="options" :options-limit="50" @input="onInput" @close="onClose"
                 @search-change="findUsers" track-by="key" label="label" :multiple="isMultiple"
                 :close-on-select="isCloseOnSelect" :internal-search="false" :loading="isLoading"
                 :clear-on-select="isClearOnSelect" :preserve-search="false" :preselect-first="false"
                 :placeholder="$t('comp.userselect.user.placeholder')" :selectLabel="$t('select.selectLabel')"
                 :selectedLabel="$t('select.selectedLabel')" :deselectLabel="$t('select.deselectLabel')"
                 :blockKeys="['Delete']" :disabled="isDisabled" :custom-label="selectLabel">
      <template slot="noOptions">{{ $t('select.noOptions') }}</template>
      <template slot="noResult">{{ $t('select.noResults') }}</template>
    </multiselect>
  </div>

</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'
import Multiselect from 'vue-multiselect'
import UserService from '@/services/user.service'

export default {
  name: 'UserSelect',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    Multiselect
  },
  props: {
    selectedUser: {
      type: Object,
      default: null
    },
    selectedUsers: {
      type: Array,
      default: null
    },
    view: {
      type: String,
      required: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      query: '',
      options: [],
      selected: false,
      isLoading: false,
      isMultiple: false
    }
  },
  computed: {
    isCloseOnSelect () {
      return !this.isMultiple
    },
    isClearOnSelect () {
      return this.isMultiple
    }
  },
  watch: {
    selectedUser: function () {
      this.preProcessSelectedUser()
    },
    selectedUsers: function () {
      this.preProcessSelectedUsers()
    }
  },
  beforeMount () {
    this.setIsMultiple()
    this.isMultiple ? this.preProcessSelectedUsers() : this.preProcessSelectedUser()
  },
  methods: {
    setIsMultiple () {
      if (this.selectedUsers) { this.isMultiple = true }
    },
    findUsers (query) {
      if (query && query.length >= 3) {
        this.isLoading = true
        const userService = new UserService(this.view)
        userService.select({ filter: query }).then((users) => {
          this.options = users // Sorted in backend
          this.isLoading = false
        }).catch(error => {
          this.options = []
          this.isLoading = false
          console.error(error)
        })
      }
    },
    reset () {
      this.query = ''
      this.options = []
    },
    onClose () {
      this.reset()
    },
    onInput (value) {
      if (this.isMultiple) {
        this.$emit('update:selectedUsers', value)
      } else {
        this.$emit('update:selectedUser', value)
      }
      this.reset()
    },
    preProcessSelectedUser () {
      this.selected = this.selectedUser ? this.selectedUser : null
    },
    preProcessSelectedUsers () {
      this.selected = this.selectedUsers ? this.selectedUsers : []
    },
    selectLabel ({ key, label }) {
      return `${label} [${key}]`
    }
  }
}
</script>
