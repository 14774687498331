const thisYear = new Date().getFullYear()

export default {
  data: function () {
    return {
      thisYear: thisYear, // Constant the current year
      selectedYear: thisYear, // Defaults to the current year, will be changed
      yearOptions: [2022, 2023, 2024, 2025, 2026, 2027] // Central array for year selectors
    }
  }
}
